enum Partner {
  WYRE = 14,
  TRANSFER_TO = 20,
  TRANGLO,
  INSTAREM,
  VCBR,
  BRI,
  X_CURRENT = 26,
  MONEY_GRAM,
  THUNES = 37,
  LIGHTNET = 52,
  RIPPLE_NET,
  DUTCH_BANGLA,
  NIC,
  TERRAPAY,
  DEEMONEY = 58,
  RAPYD = 61,
  FAYSAL,
  CZCB,
  TRANSWAP,
  BRDGX = 80,
  ANALOGUE_PARTNER = 100,
  CURRENCY_CLOUD_MTO = 68,
  MOOV = 81,
  EMQ = 82,
  REMITEE = 83,
  TENCENT = 84,
  NINE_PAY = 85,
  CONTACT_SYS = 86,
  SMART_SWIFT = 87,
  FINFAN = 88,
  WING = 89,
  NOVATTI = 91,
  ALIPAY = 92,
  BCA = 93,
  DOKU = 98,
  BAOKIM = 106,
  FINMO = 104,
  PINGPONG = 108,
  CORPAY = 109
}
export default Partner
