



















































































































import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import DataTable from '@/components/shared/DataTable.vue'
import RemittanceSearch from '@/types/apiRequest/RemittanceSearch'
import AdminMenuTypeStatus from '@/enums/AdminMenuTypeStatus'
import { getEnumOptions, getEnumValueArray, getEnumValueByKey } from '@/lib/Utils'
import { TableElementRemittance } from '@/types'
import { remittanceStatusLabel } from '@/data/RemittanceStatusLabel'
import RemittanceStatusEnum from '@/enums/RemittanceStatus'
import FormItemsOption from '@/types/FormItemsOption'
import { receivableCountriesWithUsdAnywhere } from '@/data/Countries'
import Country from '@/enums/Country'
import Partner from '@/enums/Partner'
import BasicDialog from '@/components/commons/BasicDialog.vue'
import TextObjectTable from '@/components/shared/TextObjectTable.vue'
import { RecipientWithComment } from '@/types/Recipient'
import FormItems from '@/components/shared/FormItems.vue'
import SearchModal from '@/components/shared/SearchModal.vue'
import RemittanceStatus from '@/types/apiRequest/RemittanceStatus'
import RemittancePartner from '@/types/apiRequest/RemittancePartner'
import dayjs from 'dayjs'
import TransferDetailModal from '@/components/shared/TransferDetailModal.vue'
import ExportingExcelModal from '@/components/shared/ExportingExcelModal.vue'
import { ITransfersPresentation, TransfersPresentation } from '@/presentation/transfers/TransfersPresentation'
import { TransferTitles } from '@/data/TransferTitles'
import { TextObjectTablePresentation } from '@/presentation/transfers/TextObjectTablePresentation'
import { TransferDetailPresentation } from '@/presentation/transfers/TransferDetailPresentation'
import { Getter, Mutation } from 'vuex-class'
import SetExternalIdModal from '@/components/shared/SetExternalIdModal.vue'
import { enEnum } from '@/lokalise/enumLocale/en'

interface ListCountSelectorTypes {
  TEN: number
  THIRTY: number
  FIFTY: number
}

@Component({
  components: {
    ExportingExcelModal,
    SetExternalIdModal,
    TransferDetailModal,
    SearchModal,
    FormItems,
    BasicDialog,
    TextObjectTable,
    DataTable
  }
})
export default class Transfers extends Vue {
  @Getter getTextObjectStatus: any
  @Getter getTransferDetailModal: any
  @Getter getTransferDetailField: any
  @Getter getClickedTransferDetailId: any

  @Mutation closeTransferDetailModal: any
  @Mutation closeTextObjectContent: any

  transfersPresentation: ITransfersPresentation = new TransfersPresentation()
  textObjectTablePresentation: TextObjectTablePresentation = TextObjectTablePresentation.getInstance()
  transferDetailPresentation: TransferDetailPresentation = TransferDetailPresentation.getInstance()

  routeParam = ''

  transferTitles: Dictionary<string> = TransferTitles

  selectedTransfers: Array<TableElementRemittance> = []

  unit = 10

  searchModal = false
  exportingExcelModal = false
  exportingExcelModalTitle: 'Accounting' | 'Reporting' = 'Accounting'
  setExternalIdModal = false

  public LIST_COUNT_SELECTOR_TYPES: ListCountSelectorTypes = {
    TEN: 10,
    THIRTY: 30,
    FIFTY: 50
  }

  remittanceSearchParam: RemittanceSearch = {
    admin_menu_type: getEnumValueByKey(AdminMenuTypeStatus, this.routeParam),
    page: 1,
    unit: this.unit
  }

  searchCountry: FormItemsOption = {
    prop: 'selectCountry',
    type: 'select',
    value: 'all',
    emitHandlerName: 'onChangeSelectCountry',
    useSelectAll: true,
    filterable: true,
    selectAllLabel: 'All Received Country',
    class: 'search-country-select',
    options: [
      { label: 'Korea (KR)', value: 'KR' },
      ...receivableCountriesWithUsdAnywhere
        .sort((a, b) => {
          return a < b ? -1 : a > b ? 1 : 0
        })
        .map(iso => {
          return {
            label: `${enEnum[iso]} (${iso})`,
            value: iso
          }
        })
    ]
  }

  searchStatusRadio: FormItemsOption = {
    prop: 'statusSearch',
    type: 'radioGroup',
    value: [],
    emitHandlerName: 'onClickSearchStatus',
    options: []
  }

  searchStatus: FormItemsOption = {
    prop: 'statusSearch',
    type: 'select',
    value: 'all',
    emitHandlerName: 'onClickSearchStatus',
    useSelectAll: true,
    selectAllLabel: 'All Status',
    class: 'status-search-select',
    options: getEnumOptions(RemittanceStatusEnum, getEnumValueArray(RemittanceStatusEnum))
  }

  searchButton: FormItemsOption = {
    prop: 'searchButton',
    type: 'button',
    emitHandlerName: 'onClickSearchButton',
    class: 'action-button search-button',
    label: 'Search'
  }

  searchActionOptions: Dictionary<Array<FormItemsOption>> = {
    processing: [this.searchCountry, this.searchStatusRadio, this.searchButton],
    failed: [this.searchCountry, this.searchButton],
    complete: [this.searchCountry, this.searchButton],
    aml: [this.searchCountry, this.searchButton],
    all: [this.searchCountry, this.searchStatus, this.searchButton],
    analogue: [this.searchStatusRadio, this.searchButton]
  }

  searchFormItems: Array<FormItemsOption> = []

  partnerBatchAction: FormItemsOption = {
    prop: 'partner',
    type: 'select',
    value: undefined,
    emitHandlerName: 'onPartnerAction',
    placeHolder: 'Partners',
    class: 'partner-select',
    options: getEnumOptions(Partner, [
      Partner.WYRE,
      Partner.BRI,
      Partner.INSTAREM,
      Partner.MONEY_GRAM,
      Partner.TRANGLO,
      Partner.TRANSFER_TO,
      Partner.VCBR,
      Partner.X_CURRENT,
      Partner.THUNES,
      Partner.LIGHTNET,
      Partner.RIPPLE_NET,
      Partner.DUTCH_BANGLA,
      Partner.NIC,
      Partner.TERRAPAY,
      Partner.DEEMONEY,
      Partner.RAPYD,
      Partner.FAYSAL,
      Partner.CZCB,
      Partner.TRANSWAP,
      Partner.BRDGX,
      Partner.CURRENCY_CLOUD_MTO,
      Partner.MOOV,
      Partner.EMQ,
      Partner.REMITEE,
      Partner.TENCENT,
      Partner.NINE_PAY,
      Partner.CONTACT_SYS,
      Partner.SMART_SWIFT,
      Partner.FINFAN,
      Partner.WING,
      Partner.NOVATTI,
      Partner.ALIPAY,
      Partner.BCA,
      Partner.DOKU,
      Partner.BAOKIM,
      Partner.FINMO,
      Partner.PINGPONG,
      Partner.CORPAY
    ])
  }

  transferStatusAction: FormItemsOption = {
    prop: 'transferStatus',
    type: 'select',
    value: undefined,
    emitHandlerName: 'onTransferStatusAction',
    placeHolder: 'Transfer Status',
    class: 'transfer-status-select',
    options: []
  }

  batchActionOptions: Dictionary<Array<FormItemsOption>> = {
    processing: [this.partnerBatchAction, this.transferStatusAction],
    failed: [this.partnerBatchAction, this.transferStatusAction],
    complete: [this.transferStatusAction],
    aml: [this.partnerBatchAction, this.transferStatusAction],
    all: [this.transferStatusAction],
    analogue: [this.partnerBatchAction, this.transferStatusAction]
  }

  batchFormItems: Array<FormItemsOption> = []

  accountingAction: FormItemsOption = {
    prop: 'accounting',
    type: 'button',
    emitHandlerName: 'onClickAccountingButton',
    class: 'action-button accounting-action-button',
    label: 'Accounting'
  }

  reportingAction: FormItemsOption = {
    prop: 'reporting',
    type: 'button',
    emitHandlerName: 'onClickReportingButton',
    class: 'action-button reporting-action-button',
    label: 'Reporting'
  }

  setExternalIdAction: FormItemsOption = {
    prop: 'setExternal',
    type: 'button',
    emitHandlerName: 'onClickSetExternalButton',
    class: 'action-button set-external-button',
    label: 'Set External ID'
  }

  extraActionOptions: Dictionary<Array<FormItemsOption>> = {
    processing: [this.accountingAction, this.reportingAction, this.setExternalIdAction],
    failed: [this.accountingAction, this.reportingAction],
    complete: [this.accountingAction, this.reportingAction],
    aml: [this.accountingAction, this.reportingAction],
    all: [this.accountingAction, this.reportingAction],
    analogue: [this.accountingAction, this.reportingAction]
  }

  extraActionFormItems: Array<FormItemsOption> = []

  setUnit(value: number): void {
    this.unit = value
    this.remittanceSearchParam.unit = value
  }

  requestTransferWithChangedUnit(selectedValue: number): void {
    this.setUnit(selectedValue)
    this.requestTransfer()
  }

  get getTitle(): string {
    return `송금 건별 조회 - ${this.transferTitles[this.routeParam]}`
  }

  get isRemittanceSelected(): boolean {
    const isEmpty = !this.selectedTransfers.length
    if (isEmpty) this.$message('No Transfer has selected')
    return !isEmpty
  }

  onClickSearchCancel() {
    this.searchModal = !this.searchModal
  }

  async onChangePage(page: number) {
    this.remittanceSearchParam.page = page
    await this.requestTransfer()
  }

  async onChangeSelectCountry(value: keyof typeof Country | 'all') {
    this.remittanceSearchParam.received_country = value === 'all' ? undefined : value
    this.remittanceSearchParam.page = 1
    await this.requestTransfer()
  }

  async onClickSearchStatus(value: RemittanceStatusEnum | 'all') {
    if (value === 'all') {
      this.remittanceSearchParam.remittance_status = undefined
    } else {
      this.remittanceSearchParam.remittance_status = [value]
      if (`${value}` === `${RemittanceStatusEnum.TRANSFER_QUEUE_ERROR}`) {
        const enqueueErrors = [
          `${RemittanceStatusEnum.AML_ERROR}`,
          `${RemittanceStatusEnum.IN_QUEUE_ERROR}`,
          `${RemittanceStatusEnum.TIMEOUT}`
        ]
        this.remittanceSearchParam.remittance_status =
          this.remittanceSearchParam.remittance_status.concat(enqueueErrors)
      }
    }
    this.remittanceSearchParam.page = 1
    await this.requestTransfer()
  }

  onClickSearchButton() {
    this.searchModal = true
  }

  onPartnerAction(value: Partner) {
    this.partnerBatchAction.value = undefined
    if (!this.isRemittanceSelected) return
    const message =
      `Convert <span class="color-primary">${this.selectedTransfers.length}</span> rows you selected to <span class="color-primary">${Partner[value]}</span>` +
      '<br>Is it Right ?'
    this.$confirm(message, { dangerouslyUseHTMLString: true })
      .then(async () => {
        await this.updateRemittancePartner(value)
      })
      .catch(() => {})
  }

  onTransferStatusAction(value: RemittanceStatusEnum) {
    this.transferStatusAction.value = undefined
    if (!this.isRemittanceSelected) return
    const message =
      `Convert <span class="color-primary">${this.selectedTransfers.length}</span> rows you selected to <span class="color-primary">${RemittanceStatusEnum[value]}</span>` +
      '<br>Is it Right ?'
    this.$confirm(message, { dangerouslyUseHTMLString: true })
      .then(async () => {
        await this.updateRemittanceStatus(value)
      })
      .catch(() => {})
  }

  onAccountingAction() {
    this.exportingExcelModalTitle = 'Accounting'
    this.exportingExcelModal = true
  }

  onReportingAction() {
    this.exportingExcelModalTitle = 'Reporting'
    this.exportingExcelModal = true
  }

  onClickSetExternal() {
    this.setExternalIdModal = true
  }

  onSelectRow(selectedRows: Array<TableElementRemittance>) {
    this.selectedTransfers = selectedRows
  }

  setBatchActionOptions() {
    const transferStatusActionOptions: Dictionary<Array<RemittanceStatusEnum>> = {
      processing: [
        RemittanceStatusEnum.CANCEL,
        RemittanceStatusEnum.REFUND,
        RemittanceStatusEnum.ADMIN_CHECK,
        RemittanceStatusEnum.ANALOGUE_CHECK,
        RemittanceStatusEnum.ANALOGUE_PROCESSING,
        RemittanceStatusEnum.COMPLETE
      ],
      failed: [
        RemittanceStatusEnum.CANCEL,
        RemittanceStatusEnum.REFUND,
        RemittanceStatusEnum.COMPLETE,
        RemittanceStatusEnum.ANALOGUE_CHECK,
        RemittanceStatusEnum.ANALOGUE_PROCESSING
      ],
      complete: [
        RemittanceStatusEnum.CANCEL,
        RemittanceStatusEnum.REFUND,
        RemittanceStatusEnum.ADMIN_CHECK,
        RemittanceStatusEnum.ANALOGUE_CHECK,
        RemittanceStatusEnum.ANALOGUE_PROCESSING
      ],
      aml: [
        RemittanceStatusEnum.CANCEL,
        RemittanceStatusEnum.REFUND,
        RemittanceStatusEnum.ADMIN_CHECK,
        RemittanceStatusEnum.ANALOGUE_CHECK,
        RemittanceStatusEnum.ANALOGUE_PROCESSING,
        RemittanceStatusEnum.COMPLETE
      ],
      all: [
        RemittanceStatusEnum.CANCEL,
        RemittanceStatusEnum.REFUND,
        RemittanceStatusEnum.ADMIN_CHECK,
        RemittanceStatusEnum.ANALOGUE_CHECK,
        RemittanceStatusEnum.ANALOGUE_PROCESSING,
        RemittanceStatusEnum.COMPLETE
      ],
      analogue: [
        RemittanceStatusEnum.CANCEL,
        RemittanceStatusEnum.REFUND,
        RemittanceStatusEnum.ANALOGUE_CHECK,
        RemittanceStatusEnum.ANALOGUE_PROCESSING,
        RemittanceStatusEnum.COMPLETE
      ]
    }
    const status: Array<RemittanceStatusEnum> = transferStatusActionOptions[this.routeParam]
    this.transferStatusAction.options = getEnumOptions(RemittanceStatusEnum, status)
    this.batchFormItems = this.batchActionOptions[this.routeParam]
    this.batchFormItems = Array(...this.batchFormItems)
  }

  setSearchActionOptions() {
    this.searchFormItems = this.searchActionOptions[this.routeParam]
    this.searchFormItems = Array(...this.searchFormItems)
  }

  setExtraActionOptions() {
    this.extraActionFormItems = this.extraActionOptions[this.routeParam]
    this.extraActionFormItems = Array(...this.extraActionFormItems)
  }

  setSearchParams(searchFields: Dictionary<any>) {
    this.remittanceSearchParam.remittance_id = searchFields.remittance_id
    this.remittanceSearchParam.remittance_group_id = searchFields.remittance_group_id
    this.remittanceSearchParam.remittance_status = searchFields.remittance_status
    this.remittanceSearchParam.recipient_id = searchFields.recipient_id
    this.remittanceSearchParam.recipient_name = searchFields.recipient_name
    this.remittanceSearchParam.recipient_nationality = searchFields.recipient_nationality
    this.remittanceSearchParam.recipient_phone_number = searchFields.recipient_phone_number
    this.remittanceSearchParam.external_id = searchFields.external_id
    this.remittanceSearchParam.external_partner = searchFields.external_partner
    this.remittanceSearchParam.external_status = searchFields.external_status
    this.remittanceSearchParam.corp_name = searchFields.corp_name
    this.remittanceSearchParam.corp_id = searchFields.corp_id
    this.remittanceSearchParam.fx_task_id = searchFields.fx_task_id
    this.remittanceSearchParam.analogue_filter = searchFields.analogue_filter
    if (searchFields.remittance_created_at) {
      const from: string = dayjs(new Date(searchFields.remittance_created_at[0])).format('YYYY-MM-DDT00:00:00.0Z')
      const to: string = dayjs(new Date(searchFields.remittance_created_at[1])).format('YYYY-MM-DDT23:59:59.0Z')
      this.remittanceSearchParam.remittance_created_at = { from, to }
    }
    if (searchFields.remittance_updated_at) {
      const from: string = dayjs(new Date(searchFields.remittance_updated_at[0])).format('YYYY-MM-DDT00:00:00.0Z')
      const to: string = dayjs(new Date(searchFields.remittance_updated_at[1])).format('YYYY-MM-DDT23:59:59.0Z')
      this.remittanceSearchParam.remittance_updated_at = { from, to }
    }
    this.remittanceSearchParam.page = 1
  }

  refreshSearchDetailParams() {
    this.remittanceSearchParam.remittance_id = undefined
    this.remittanceSearchParam.remittance_group_id = undefined
    this.remittanceSearchParam.remittance_status = undefined
    this.remittanceSearchParam.received_country = undefined
    this.remittanceSearchParam.recipient_name = undefined
    this.remittanceSearchParam.recipient_nationality = undefined
    this.remittanceSearchParam.recipient_birth = undefined
    this.remittanceSearchParam.external_id = undefined
    this.remittanceSearchParam.external_partner = undefined
    this.remittanceSearchParam.external_status = undefined
    this.remittanceSearchParam.corp_name = undefined
    this.remittanceSearchParam.corp_id = undefined
    this.remittanceSearchParam.fx_task_id = undefined
    this.remittanceSearchParam.remittance_created_at = undefined
    this.remittanceSearchParam.remittance_updated_at = undefined
    this.remittanceSearchParam.analogue_filter = 3
    this.remittanceSearchParam.page = 1
  }

  refreshQuickSearchParams() {
    this.remittanceSearchParam.received_country = undefined
    this.remittanceSearchParam.remittance_status = undefined
    this.remittanceSearchParam.page = 1
  }

  refreshSearchParams() {
    this.refreshQuickSearchParams()
    this.refreshSearchDetailParams()
  }

  refreshOptions() {
    this.refreshSearchParams()
    this.searchFormItems.forEach(formItem => {
      if (formItem.type === 'select') formItem.value = 'all'
      if (formItem.type === 'radioGroup') formItem.value = []
    })
    this.partnerBatchAction.value = undefined
    this.transferStatusAction.value = undefined
    this.transferStatusAction.options = []
  }

  setSearchRadioOptions() {
    if (this.routeParam === 'processing') {
      this.searchStatusRadio.options = getEnumOptions(RemittanceStatusEnum, [
        RemittanceStatusEnum.TRANSFER_IN_QUEUE,
        RemittanceStatusEnum.COMPLIANCE_DONE,
        RemittanceStatusEnum.TRANSFER_QUEUE_ERROR,
        RemittanceStatusEnum.TRANSFERING
      ])
    } else if (this.routeParam === 'analogue') {
      this.searchStatusRadio.options = [{ label: 'All', value: 'all' }].concat(
        getEnumOptions(RemittanceStatusEnum, [
          RemittanceStatusEnum.ANALOGUE_CHECK,
          RemittanceStatusEnum.ANALOGUE_PROCESSING
        ])
      )
      this.searchStatusRadio.value = RemittanceStatusEnum.ANALOGUE_CHECK
    }
  }

  getRemittanceStatusByValue(object: { [key: number]: string }, value: string): number {
    return Number(Object.keys(object).find(key => object[Number(key)] === value))
  }

  getToAnalogueBoolean(from: number, to: number): boolean {
    if (from !== 145 && from !== 146 && (to === 145 || to === 146)) return true
    else return false
  }

  async detailSearch(searchFields: Dictionary<any>) {
    this.refreshSearchDetailParams()
    this.setSearchParams(searchFields)
    this.searchModal = false
    await this.requestTransfer()
  }

  async updateTransferDetail(transferDetailField: RecipientWithComment) {
    const response: any = await this.transferDetailPresentation.updateTransferDetail(transferDetailField)
    const isSuccessResponse: boolean = response.code === 200
    if (!isSuccessResponse) return
    await this.requestTransfer()
    this.closeTransferDetailModal()
  }

  async updateRemittanceStatus(value: any) {
    const updateRemittanceStatusParam: RemittanceStatus = {
      data: this.selectedTransfers.map(transfer => {
        return {
          remittance_id: Number(transfer.transfer_id.label),
          current_status: this.getRemittanceStatusByValue(remittanceStatusLabel, transfer.status._text),
          to_analogue: this.getToAnalogueBoolean(
            this.getRemittanceStatusByValue(remittanceStatusLabel, transfer.status._text),
            value
          )
        }
      }),
      remittance_status: value
    }
    const response: any = await this.transfersPresentation.updateRemittanceStatus(updateRemittanceStatusParam)
    const isSuccessResponse: boolean = response.code === 200
    if (!isSuccessResponse) return
    this.$message({ message: 'Update complete.', type: 'success' })
    await this.requestTransfer()
  }

  async updateRemittancePartner(value: Partner) {
    const data: RemittancePartner = {
      remittance_id: this.selectedTransfers.map(transfer => Number(transfer.transfer_id.label)),
      remittance_partner_id: value
    }
    const response = await this.transfersPresentation.updateRemittancePartner(data)
    const isSuccessResponse: boolean = response.code === 200
    if (!isSuccessResponse) {
      const message = response.code === 2000 ? '이미 송금이 진행 중입니다.' : '재 송금 불가능한 상태입니다.'
      this.$message({
        message: `${response.code} ${message}`,
        type: 'error'
      })
      return
    }
    await this.requestTransfer()
  }

  setRemittanceByQuery(query: object) {
    this.remittanceSearchParam = Object.assign({}, this.remittanceSearchParam, query)
  }

  @Watch('$route', { immediate: true })
  async setDataResource(value: any) {
    const routeParams = value.params
    const paramKey: string = Object.keys(routeParams)[0]
    this.routeParam = routeParams[paramKey]
    this.refreshOptions()
    this.setSearchRadioOptions()
    this.setSearchActionOptions()
    this.setBatchActionOptions()
    this.setExtraActionOptions()
    this.setRemittanceByQuery(value.query)
    await this.requestTransfer()
  }

  async requestTransfer(): Promise<void> {
    let adminMenuType
    if (this.routeParam === 'analogue') {
      adminMenuType = '5'
      this.remittanceSearchParam.analogue_filter = 1
    } else {
      adminMenuType = getEnumValueByKey(AdminMenuTypeStatus, this.routeParam)
    }
    await this.transfersPresentation.requestTransfer(this.remittanceSearchParam, adminMenuType)
  }
}
